import type { Contact } from "~/types/drupal_jsonapi";
import { For, Show } from "solid-js";

import IconMail from "~/img/icons/mail.svg";
import IconCall from "~/img/icons/call.svg";

export default function ContactPerson(props: {
  contact: Contact;
  withoutCompany?: boolean;
}) {
  return (
    <>
      <div class="contact-person" data-test="contact">
        <Show when={props.contact.field_company && !props.withoutCompany}>
          <h3 data-test="company">{props.contact.field_company!.name}</h3>
        </Show>
        <h4 data-test="name">{props.contact.name}</h4>
        <Show when={props.contact.field_profession}>
          <span class="item pro" data-test="profession">
            {props.contact.field_profession}
          </span>
        </Show>
        <Show when={props.contact.field_email}>
          <span class="item" data-test="email">
            <i aria-hidden="true" class="cog-icon">
              <IconMail />
            </i>
            <span>
              <a href={`mailto:${props.contact.field_email}`}>
                {props.contact.field_email}
              </a>
            </span>
          </span>
        </Show>
        <Show
          when={
            props.contact.field_phone && props.contact.field_phone.length > 0
          }
        >
          <span class="item" data-test="phones">
            <i aria-hidden="true" class="cog-icon">
              <IconCall />
            </i>
            <span>
              <For each={props.contact.field_phone}>
                {(phone) => {
                  return (
                    <>
                      <a href={`tel:${phone}`} data-test="phone">
                        {phone}
                      </a>
                      <br />
                    </>
                  );
                }}
              </For>
            </span>
          </span>
        </Show>
      </div>
    </>
  );
}
